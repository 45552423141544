.valentine-container{
    position: absolute;
    background-color: pink!important;
    text-align: center;
    font-size: 50px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.valentine-container h1{
    color: white;
    text-align: center;
}

.valentine-container button{
    background-color: lightcoral;
    color: white;
    border: 2px solid plum;   
    margin-top: 20px;
    cursor: pointer;
}

.valentine-container button:hover{
    background-color: plum;
    color: white;
}