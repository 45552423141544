 /* Style inputs with type="text", select elements and textareas */
 input[type=text] {
    width: 60%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
    text-align: center;
  }
  textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
  }
  
  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  /* Add a background color and some padding around the form */
  .container {
    border-radius: 5px;
    background-color: black;
    width: 50%;
    padding: 2% 25% 5% 25%; 
    border-top: solid 2px goldenrod;
  } 
  .container h1{
    text-align: center;
    color: white;
    padding-bottom: 3%;
    font-family: 'RandyGGRegular' !important;
  }

  .social-links{
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 2%;
  }

.g-recaptcha{
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
}