:root { 
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif , cursive;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: black;
  height: 100%;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'RandyGGRegular';
  src: url(fonts/RandyGGBold.ttf) format('truetype');
}

a:hover {
  color: goldenrod;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

header {
  background-color: black;
  padding: 15px 0;
}

h1{
  color: white;
  text-align: left;
  padding-bottom: 5%;
}

.title{
  color: white;
  text-shadow: rgb(105, 105, 105) 0px 2px; 
  text-align: left;
  padding-bottom: 5%;
  font-size: xx-large;  
}

nav {
  text-align: center;
  
  border-bottom: 1px solid white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline-block;
  margin-right: 20px;
}

a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
}
.main-content {
  display: flex;
  justify-content: center;
  align-items: top;
  text-align: left;
  padding: 50px;
  height: 100vh;
  background-color: black;
  color: white;
  font-family: 'RandyGGRegular' !important;
}

.content-text {
  padding-top: 5%;
  max-width: 600px;
  margin-right: 20px;
}

.content-image img {
  padding-top: 5%;
  max-width: 100%;
  height: auto;
}

.signup-title{
  color: white;
  text-align: center;
  padding-bottom: 1%;
  font-size: 3rem;
  font-family: 'RandyGGRegular' !important;
}

.signup-subtitle{
  color: white;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: 1.5rem;
  font-family: 'RandyGGRegular' !important;
}


.signup-separator {
  border-bottom: 1px solid white;
  margin-left: 30%;
  width: 40%;
}


button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: goldenrod;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
} 