.cv-container {
    padding: 5% 15% 30% 15%;
    background-image: linear-gradient(to bottom right,  black, grey);
    color: white;
}

.cv-container header {
    text-align: center;
    background: transparent !important;
}

.cv-container h1 {
    font-size: 2.5em;
    font-weight: 650;
}

.contact-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid white;
    padding-bottom: 5%;
    margin-bottom: 5%;
}

.contact-details {
    flex: 1;
    text-align: right;
}

.contact-details p {
    margin: 0;
    font-size: 1.2em;
}

.contact-details a {
    color: white;
}

.contact-separator {
    width: 1px;
    height: 20%;
}

.cv-details {
    display: inline-grid;
    grid-template-columns: 1fr 2fr; /* Equal width columns */
    gap: 3% 5% /* Adjust the gap as needed */
}

.cv-info {
    flex-direction: column;
    text-align: left;
}

.cv-info p {
    margin: 0;
    font-size: 1.1em;
}


.edu-info p {
    text-align: left;
    margin: 0;
    font-size: 1.1em;
    padding-left: 3%;
}

.cv-titles {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.cv-titles p {
    margin: 0;
    font-size: 1.1em;
    font-weight: bold;
    padding-left: 5%;
}

.edu p {
    text-align: left;
    margin: 0;
    font-size: 1.0em;
    font-weight: bold;
    padding-left: 7%;
}

.cv-titles h2 {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    font-style: italic;
}