.projects{
    background-color: black;
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'RandyGGRegular' !important;
}
  
.projects h1{
    background-color: black;
text-align: center;
color: white;
padding-top: 2%;
padding-bottom: 3%;
}

.list-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'RandyGGRegular' !important;
    gap: 1%;
}
